<template>
  <b-card>
    <form-component :tag="tag" :is-create-view="true" @onSubmit="onSubmit" />
  </b-card>
</template>

<script>
import FormComponent from '@/components/downloadable-files-tags/FormComponent';
import NotificationMixin from '@/mixins/NotificationMixin';
import { create } from '@/http/downloadable-files-tags';

export default {
  mixins: [NotificationMixin],
  components: {
    FormComponent,
  },

  data() {
    return {
      tag: {
        name: '',
      },
    };
  },

  methods: {
    async onSubmit(form) {
      try {
        await create(form);

        this.showSuccessNotification('Dane zapisane', 'Tag został dodany.');
        this.$router.push({ name: 'downloadable-files-tags-index' });
      } catch (error) {
        const message =
          error.response.data.message ?? 'Wystąpił błąd podczas dodawania tagu. Skontaktuj się ze swoim developerem.';
        this.showErrorNotification('Problem z zapisaniem danych', message);
      }
    },
  },
};
</script>
